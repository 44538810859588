import { graphql } from "gatsby";
import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";

export interface BlogPostProps {
  data: any;
}

export default function BlogPost({ data }: BlogPostProps) {
  console.log(data);
  const {
    slug,
    title,
    date,
    seoDescription,
    seoKeywords,
    seoOgImage,
    seoTitle,
  } = data.graphCmsPost;
  const seo = {
    title: seoTitle,
    keywords: seoKeywords,
    description: seoDescription,
    image: seoOgImage?.url || undefined,
  };
  const content = data.graphCmsPost.content.html;

  return (
    <>
      <SEO
        title={seo?.title || title}
        description={seo?.description}
        key={seo?.keywords}
        externalImage={seo?.image}
      />
      <Layout>
        <article className="max-w-4xl mx-auto">
          <header className="mb-10 text-center">
            <h2 className=" leading-snug">{title}</h2>
          </header>

          <div
            className="blog-article"
            dangerouslySetInnerHTML={{ __html: content }}></div>
        </article>
      </Layout>
    </>
  );
}

export const query = graphql`
  query BlogPost($slug: String) {
    graphCmsPost(slug: { eq: $slug }) {
      seoDescription
      seoKeywords
      seoTitle
      seoOgImage {
        url
      }
      slug
      title
      date
      content {
        html
      }
    }
  }
`;
